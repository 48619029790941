import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout"
import logo from "../images/logo.png"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allPortfolioJson {
        edges {
          node {
            id
            desc
            name
            images {
              childImageSharp {
                # fluid(maxHeight: 2000, quality: 100) {
                #   ...GatsbyImageSharpFluid
                #   originalName
                #   presentationWidth
                #   presentationHeight
                # }
                fixed(height: 1600, quality: 90) {
                  ...GatsbyImageSharpFixed
                  originalName
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  `)
  // console.log(data)

  return (
    <Layout>
      <SEO title="Emily" />

      <div className="slider">
        <div className="cc">
          <img src={logo} alt="logo" />
          <div>SCROLL TO VIEW</div>
        </div>
        {data.allPortfolioJson.edges.map(
          ({ node: { id, name, images, desc } }) => (
            <div key={id} className={`work-item ${!name ? "no-name" : ""}`}>
              <div
                className="work-name"
                data-img-name={images[0].childImageSharp.fixed.originalName}
              >
                {name}
                <br />
                <div dangerouslySetInnerHTML={{ __html: desc }} />
              </div>
              {images.map(
                ({
                  id,
                  childImageSharp: {
                    fixed: { srcSet, sizes, src, width, height },
                  },
                }) => (
                  // <Img
                  //   key={image.id}
                  //   fluid={fluid}
                  //   fadeIn
                  // />
                  <img
                    key={id}
                    srcSet={srcSet}
                    sizes={sizes}
                    src={src}
                    width={width}
                    height={height}
                    alt={name}
                  />
                )
              )}
            </div>
          )
        )}
      </div>
    </Layout>
  )
}

export default IndexPage
